<template>
  <v-form
    ref="form"
    @submit.prevent="onSubmit()"
  >
    <div
      v-t="
        'support.pleaseEnterYourPhoneNumberWhereOurSupportCanReachYouDirectly'
      "
      class="pt-2 text-center"
    />
    <div
      v-t="'support.youMayAlsoSpecifyTheTopicToDiscuss'"
      class="pb-2 text-center"
    />
    <v-textarea
      v-model="formData.text"
      :label="labels.text"
      :error="Boolean(errors.text)"
      :messages="errors.text"
      :rules="[v => !!v || $t('errors.fieldIsRequired')]"
      data-cy="request-callback-modal-topic"
    />
    <v-text-field
      v-model="formData.phone"
      :label="labels.phone"
      :error="Boolean(errors.phone)"
      :messages="errors.phone"
      :rules="[v => !!v || $t('errors.fieldIsRequired')]"
      data-cy="request-callback-modal-phone"
    />
    <core-element-time-zone-selector
      :time-zone="formData.timeZone || ''"
      :set-time-zone="setTimeZone"
      data-cy="request-callback-time-zone-element"
    />
    <core-element-errors-message
      :show-errors="showApiErrors"
      :errors="errors"
    />
    <div class="mt-5 text-center">
      <v-btn
        rounded
        color="primary"
        depressed
        data-cy="request-callback-modal-submit"
        type="submit"
        :loading="loading"
      >
        {{ $t('submit') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { REQUEST_TYPE_CALLBACK } from '@/constants/app'

export default {
  data () {
    return {
      formData: {}
    }
  },
  computed: {
    ...mapState('support', ['loading', 'errors', 'showApiErrors']),
    ...mapGetters('client', ['getBillingDetailDataById']),
    billingDetails () {
      return this.getBillingDetailDataById(this.$route.params.clientId)
    },
    labels () {
      return {
        text: this.$i18n.t('support.whatTopicWouldYouLikeToTalkAbout'),
        phone: this.$i18n.t('support.directNumber')
      }
    }
  },
  created () {
    this.resetFormData()
  },
  methods: {
    ...mapActions('support', ['createRequest']),
    getDefaultFormData () {
      return {
        type: REQUEST_TYPE_CALLBACK,
        phone: this.billingDetails.phone,
        clientId: this.$route.params.clientId
      }
    },
    resetFormData () {
      this.formData = this.getDefaultFormData()
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.createRequest(this.formData)
      }
    },
    setTimeZone (timeZone) {
      this.formData = { ...this.formData, timeZone }
    }
  }
}
</script>
